<template>
  <el-form ref="form" :model="form" label-width="80px" style="width: 40%">
    <el-form-item label="旧密码">
      <el-input
        v-model="form.oldPassword"
        type="password"
        placeholder="请输入旧密码"
      ></el-input>
    </el-form-item>
    <el-form-item label="新密码">
      <el-input
        v-model="form.password"
        type="password"
        placeholder="请输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item label="确认密码">
      <el-input
        v-model="form.repassword"
        type="password"
        placeholder="请确认密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">确认修改</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { resetPassword } from "@/api/api";
import md5 from 'md5-js'
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async onSubmit() {
      if (this.form.password != this.form.repassword) {
        this.$message.error("两次输入密码不一致");
        return;
      }
      let res = await resetPassword({
        oldPassword:md5(this.form.oldPassword),
        password:md5(this.form.password),

      });
      this.$message.success("修改成功，请重新登录");
      setTimeout(() => {
        this.logout();
      }, 2000);
    },
    logout() {
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("name", "");
      this.$router.replace("/login");
    },
  },
};
</script>

<style>
</style>